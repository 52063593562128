const footerData = {
    contact: {
        phone: "+1 (646) 956-29060",
        address: "105 Eelmsford ST Durham,NC 27703, US",
        email: "sdas@teqts.com",
        website: "https://teqts.com/",
    },
    copyright:
        "TEQTS. <a href='https://teqts.com/' target='_blank' rel='noopener noreferrer'>All Rights Reserved.</a>",
    socials: [
        {
            id: 1,
            icon: "fab fa-facebook-f",
            link: "https://www.facebook.com/teqtindia",
            title: "Facebook",
        },
        {
            id: 2,
            icon: "fab fa-twitter",
            link: "https://www.twitter.com/TEQTINDIA",
            title: "Twitter",
        },
        {
            id: 3,
            icon: "fab fa-instagram",
            link: "https://www.instagram.com/teqtindia",
            title: "Instagram",
        },
        {
            id: 4,
            icon: "fab fa-linkedin",
            link: "https://www.linkedin.com/company/teqtindia",
            title: "Linkedin",
        },
    ],
};

export default footerData;
